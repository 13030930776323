










import { Component, Vue, Watch } from "vue-property-decorator";
import CommentsLog from "@/components/CommentsLog.vue";
import { api } from "@/api";

@Component({
  components: {
    CommentsLog,
  },
})
export default class CreatorComments extends Vue {
  public comments = [];

  public async submitComment(payload) {
    payload.creator_id = parseInt(this.$route.params.id, 0);
    const response = await api.createComment("creator", payload);
    if (response && response.status === 200) {
      this.$refs["comment-log"]["$refs"]["mention-text-box"]["editor"][
        "clearContent"
      ]();
      await this.reloadComments();
    } else {
      this["$toast"].add({
        severity: "error",
        detail: "Failed to add comment.",
        life: 2000,
      });
    }
  }

  public async updateComment(payload: { id: number; data: any }) {
    const response = await api.updateComment(
      "creator",
      payload.id,
      payload.data
    );
    if (response && response.status === 200) {
      this.$refs["comment-log"]["$refs"]["mention-text-box"]["editor"][
        "clearContent"
      ]();
      await this.reloadComments();
      this.$refs["comment-log"]["editing"] = false;

      this["$toast"].add({
        severity: "success",
        detail: "Comment successfully updated.",
        life: 2000,
      });
    } else {
      this["$toast"].add({
        severity: "error",
        detail: "Failed to update comment.",
        life: 2000,
      });
    }
  }

  public async created() {
    await this.reloadComments();
  }

  public async reloadComments() {
    const response = await api.listComments(
      "creator",
      parseInt(this.$route.params.id, 0)
    );
    if (response && response.status === 200) {
      this.comments = response.data.reverse();
    }
  }

  public async deleteComment(commentId: number) {
    const response = await api.deleteComment("creator", commentId);
    if (response && response.status === 200) {
      await this.reloadComments();
      this["$toast"].add({
        severity: "success",
        detail: "Comment deleted.",
        life: 2000,
      });
    } else {
      this["$toast"].add({
        severity: "error",
        detail: "Failed to delete comment.",
        life: 2000,
      });
    }
  }
}
