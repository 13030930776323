




































































import { Component, Vue } from "vue-property-decorator";
import { readCreator } from "@/store/creator/getters";
import { readTeams, readOffices } from "@/store/main/getters";
import { dispatchListOffices, dispatchListTeams } from "@/store/main/actions";
import MappingGroups from "@/views/manage/mapping-group/MappingGroups.vue";
import { api } from "@/api";
import { readIsManager, readIsSuper } from "@/store/auth/getters";

@Component({
  components: {
    MappingGroups,
  },
})
export default class CreatorManagers extends Vue {
  public managers = [];

  get creator() {
    return readCreator(this.$store);
  }

  get teams() {
    return readTeams(this.$store);
  }

  get offices() {
    return readOffices(this.$store);
  }

  public getTeamName(teamId: number) {
    const filtered = this.teams.filter((item) => item.id === teamId);
    if (filtered.length) {
      return filtered[0].name;
    }
    return "N/A";
  }

  public getOfficeName(officeId: number) {
    const filtered = this.offices.filter((item) => item.id === officeId);
    if (filtered.length) {
      return filtered[0].name;
    }
    return "N/A";
  }

  public async mounted() {
    if (!this.teams.length) {
      await dispatchListTeams(this.$store);
    }
    if (!this.offices.length) {
      await dispatchListOffices(this.$store);
    }
    const creatorId = parseInt(this.$route.params.id, 0);
    if (creatorId) {
      const response = await api.listManagersByCreator(creatorId);
      if (response) {
        this.managers = Array.from(
          new Set(
            response.data.map((manager) => {
              return {
                first_name: manager.first_name,
                last_name: manager.last_name,
                email: manager.email,
                team_id: manager.team_id,
                office_id: manager.office_id,
                photo_url: manager.photo_url,
              };
            })
          )
        );
      }
    }
  }

  get isSuper() {
    return readIsSuper(this.$store);
  }

  get isManager() {
    return readIsManager(this.$store);
  }

  get readonly() {
    return !this.isManager;
  }
}
