











































































import { Component, Vue } from "vue-property-decorator";
import { readAccounts, readCreator } from "@/store/creator/getters";

@Component
export default class CreatorAccounts extends Vue {
  get creator() {
    return readCreator(this.$store);
  }

  get accounts() {
    return readAccounts(this.$store);
  }
}
