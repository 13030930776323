






























































import { Component, Vue } from "vue-property-decorator";
import {
  readManagerRoles,
  readManagers,
  readMappingGroups,
  readOffices,
  readTeams,
} from "@/store/main/getters";
import {
  dispatchListManagers,
  dispatchListMappingGroups,
  dispatchListOffices,
} from "@/store/main/actions";
import DataView from "primevue/dataview/DataView";
import { readManager } from "@/store/manager/getters";

@Component({
  components: {
    DataView: DataView,
  },
})
export default class MappingGroups extends Vue {
  public search = "";
  public filters = {};
  public loading = true;
  public rowCount = 0;
  public namedMappingGroups = [];

  get managerRoles() {
    return readManagerRoles(this.$store);
  }

  get offices() {
    return readOffices(this.$store);
  }

  public updateRowCount(e) {
    this.rowCount = e.filteredValue.length;
  }

  get mappingGroups() {
    return readMappingGroups(this.$store);
  }

  get managers() {
    return readManagers(this.$store);
  }

  public getRoleName(roleId: number) {
    if (roleId) {
      const role = this.managerRoles.filter((item) => item.id === roleId)[0];
      if (role) {
        return role.name;
      }
      return "";
    }
    return "";
  }

  public async mounted() {
    window["analytics"]["page"]("/manage/mapping-groups");
    if (!this.managers.length) {
      await dispatchListManagers(this.$store);
    }
    if (!this.offices.length) {
      await dispatchListOffices(this.$store);
    }
    await dispatchListMappingGroups(this.$store);
    this.namedMappingGroups = this.mappingGroups.map((mappingGroup) => {
      const data = mappingGroup;
      data.members = mappingGroup.template.members.map((member) => {
        const filtered = this.managers.filter(
          (manager) => manager.id == member.manager_id
        );
        const manager = filtered.length ? filtered[0] : {};
        return { ...member, ...manager };
      });
      return data;
    });
    this.loading = false;
    this.rowCount = this.mappingGroups.length;
  }
}
