



























import { Component, Vue } from "vue-property-decorator";
import { readCreator } from "@/store/creator/getters";
import CreatorEdit from "@/views/manage/creator/Creator/CreatorEdit.vue";
import CreatorAccounts from "@/views/manage/creator/Creator/CreatorEdit/CreatorAccounts.vue";
import CreatorComments from "@/views/manage/creator/Creator/CreatorEdit/CreatorComments.vue";
import CreatorManagers from "@/views/manage/creator/Creator/CreatorEdit/CreatorManagers.vue";
import {
  dispatchGetCreator,
  dispatchListAccounts,
} from "@/store/creator/actions";
import {
  dispatchListCrews,
  dispatchListManagerRoles,
} from "@/store/main/actions";

@Component({
  components: {
    CreatorEdit,
    CreatorAccounts,
    CreatorComments,
    CreatorManagers,
  },
})
export default class Creator extends Vue {
  get creator() {
    return readCreator(this.$store);
  }

  public async mounted() {
    const creatorId = parseInt(this.$route.params.id, 0);
    window["analytics"]["page"](`/manage/creators/${creatorId}`);
    await dispatchGetCreator(this.$store, { id: creatorId });
    this.$refs.creatorEdit["reset"]();
    await dispatchListCrews(this.$store);
    await dispatchListManagerRoles(this.$store);
    await dispatchListAccounts(this.$store, { id: creatorId });
  }

  beforeRouteLeave(to, from, next) {
    if (this.$refs.creatorEdit["edited"]()) {
      if (!window.confirm("Leave without saving?")) {
        return;
      }
    }
    next();
  }

  public beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  }

  preventNav(event) {
    if (!this.$refs.creatorEdit["edited"]()) {
      return;
    }
    event.preventDefault();
    event.returnValue = "";
  }
}
